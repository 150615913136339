import { useTranslation } from 'react-i18next';
import { slotColor, slotOptions } from '../utils';

export const SlotLegend = () => {
  const { t } = useTranslation();
  return (
    <div className="px-2 sm:px-0 grid grid-cols-2 sm:flex justify-start items-center gap-x-2 pb-4 gap-y-2 sm:flex-nowrap sm:gap-y-0">
      {slotOptions.map(({ label, value }) => (
        <div
          key={value}
          className="flex justify-start items-center text-xs font-sans font-normal gap-x-1 whitespace-nowrap"
        >
          <div className="w-3 h-3" style={{ backgroundColor: slotColor[value] }} />
          <span className="mr-2">{value === 'RESERVATION' ? t('reservation') : label}</span>
        </div>
      ))}
    </div>
  );
};
