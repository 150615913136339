import { Route, Switch } from 'react-router-dom';
import DailyOverview from '@organisms/DailyOverview';
import AdditionalTasks from '@organisms/AdditionalTasks';
import Tasks from '@organisms/Tasks';
import routes from '@constants/routes';
import { TaskType } from '@typings/enums';
import { useState } from 'react';
import WeeklyView from '@organisms/WeeklyView';
import RoomAssignment from '@organisms/RoomAssignment';
import Notifications from '@organisms/Notifications';
import Navbar from '@organisms/Navbar/Navbar';
import { startOfDay } from 'date-fns';
import dateUtils from '@utils/dateUtils';
import usePropertiesState from '@context/propertiesContext';
import UnitView from '@organisms/UnitView';
import RoomRack from '@organisms/RoomRack';
import useAppContext from '@context/appContext';

export default function HomeContent() {
  const today = startOfDay(dateUtils.now());
  const [taskViewDate, setTaskViewDate] = useState<Date>(today);
  const [weeklyViewDate, setWeeklyViewDate] = useState<Date>(today);

  const { isContentOnly, isFullScreen } = useAppContext();

  const { selectedProperty } = usePropertiesState();

  return (
    <div className={'flex flex-1 min-h-0 flex-col w-full'}>
      {!isContentOnly && (
        <Navbar
          taskViewDate={taskViewDate}
          setTaskViewDate={setTaskViewDate}
          weeklyViewDate={weeklyViewDate}
          setWeeklyViewDate={setWeeklyViewDate}
        />
      )}
      <div
        className={`flex flex-col space-y-5 flex-1 ${isFullScreen ? 'px-2' : 'px-3 pb-5'} ${
          !isContentOnly && 'md:pl-44'
        } overflow-y-auto`}
      >
        <Switch>
          <Route path={routes.WEEKLY}>
            <WeeklyView date={weeklyViewDate} />
          </Route>
          <Route path={routes.NOTIFICATIONS}>
            <Notifications />
          </Route>
          {selectedProperty && (
            <>
              <Route path={routes.TASKS}>
                <Tasks />
              </Route>
              <Route path={routes.LOST_AND_FOUND}>
                <AdditionalTasks type={TaskType.LOST_AND_FOUND} date={taskViewDate} />
              </Route>
              <Route path={routes.DAMAGE_REPORT}>
                <AdditionalTasks type={TaskType.DAMAGE} date={taskViewDate} />
              </Route>
              <Route path={routes.UNIT_DETAILS}>
                <UnitView />
              </Route>
              <Route path={routes.ROOM_RACK}>
                <RoomRack />
              </Route>
              <Route exact path={'/'}>
                <DailyOverview />
              </Route>
              <Route exact path={routes.ASSIGN_ROOMS}>
                <RoomAssignment />
              </Route>
            </>
          )}
        </Switch>
      </div>
    </div>
  );
}
