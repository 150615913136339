import { Integration, SearchParams } from '@context/appContext';

export const isSameAsCurrentUrl = (url: string) => {
  return new URL(url).pathname === window.location.pathname;
};

// Only used outside react components
// Use App context otherwise
export function hasIntegrationParam(param: Integration): boolean {
  try {
    const params = new URLSearchParams(window.location.search);
    const integration = params.get(SearchParams.INTEGRATION);

    if (!integration) {
      return false;
    }

    return integration === param;
  } catch (error) {
    console.error('Error reading integration parameter:', { window: window.location, error });
    return false;
  }
}
