import { allMaintenanceSlotReasons, SlotItem } from './RoomRackTable/types';
import { SlotOption, SlotType } from './types';
import { MaintenanceSlotType, RoomRackType } from '@typings/types';

export const maintenanceSlotOptions: SlotOption[] = [
  {
    label: 'Out of order',
    value: MaintenanceSlotType.OUT_OF_ORDER,
  },
  {
    label: 'Out of service',
    value: MaintenanceSlotType.OUT_OF_SERVICE,
  },
  {
    label: 'Out of inventory',
    value: MaintenanceSlotType.OUT_OF_INVENTORY,
  },
];

export const slotOptions: SlotOption[] = [
  {
    label: 'Reservation',
    value: 'RESERVATION',
  },
  ...maintenanceSlotOptions,
];

const defaultSlotStyle = {
  border: '0px solid',
  color: 'black',
  fontFamily: 'Proxima-Nova, sans',
};

export const slotColor: Record<SlotType, string> = {
  ['RESERVATION']: '#DDDDDD',
  [MaintenanceSlotType.OUT_OF_ORDER]: '#EF8B69',
  [MaintenanceSlotType.OUT_OF_SERVICE]: '#6794F3',
  [MaintenanceSlotType.OUT_OF_INVENTORY]: '#66AE7E',
};

export const formatSlotsData = (roomRack: RoomRackType) => {
  const slotData: SlotItem[] = [];
  for (const { unitId, unitNumber, maintenances, reservations } of roomRack) {
    for (const { id, checkIn, checkOut, externalId } of reservations) {
      slotData.push({
        id,
        group: unitId,
        roomNumber: unitNumber,
        title: externalId,
        externalId: externalId,
        start_time: checkIn.getTime(),
        end_time: checkOut.getTime(),
        stackOrder: 0,
        type: 'RESERVATION',
        itemProps: { style: { ...defaultSlotStyle, background: slotColor['RESERVATION'] } },
      });
    }
    for (const { id, from, to, type, description, endWithCleaning, reason, isDeleted } of maintenances) {
      if (!isDeleted) {
        slotData.push({
          id,
          group: unitId,
          roomNumber: unitNumber,
          title: reason,
          start_time: from.getTime(),
          end_time: to.getTime(),
          stackOrder: 1,
          type,
          reason: reason as allMaintenanceSlotReasons,
          cleanAfterBlock: endWithCleaning,
          description: description ?? undefined,
          itemProps: { style: { ...defaultSlotStyle, background: slotColor[type] } },
        });
      }
    }
  }
  return slotData;
};
