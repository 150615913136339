import {
  DamageReportActionRequired,
  DamageReportArea,
  StandardizedDamageReport,
  TaskPriority,
  Team,
} from '@typings/enums';
import { CleaningInterval } from '@typings/types';

const standardizedDamageReports = {
  [StandardizedDamageReport.BATHROOM_FAN_LOUD]: 'Вентилятор в ванной громкий',
  [StandardizedDamageReport.LIGHT_NOT_WORKING]: 'Свет не работает',
  [StandardizedDamageReport.SINK_BLOCKED]: 'Раковина заблокирована',
  [StandardizedDamageReport.SHOWERDRAIN_BLOCKED]: 'Душевой сток заблокирован',
  [StandardizedDamageReport.BATHROOM_FILTER]: 'Фильтр для ванной',
  [StandardizedDamageReport.SINK]: 'Раковина',
  [StandardizedDamageReport.TOILET]: 'Туалет',
  [StandardizedDamageReport.SHOWER]: 'Душ',
  [StandardizedDamageReport.STOVE]: 'Печь',
  [StandardizedDamageReport.FRIDGE_MINIBAR]: 'Холодильник / Минибар',
  [StandardizedDamageReport.DOOR]: 'Дверь',
  [StandardizedDamageReport.WINDOW]: 'Окно',
  [StandardizedDamageReport.AC]: 'AC',
  [StandardizedDamageReport.WIFI]: 'вай фай',
  [StandardizedDamageReport.LIGHTBULB]: 'Лампочка',
  [StandardizedDamageReport.CHAIRS]: 'Стулья',
  [StandardizedDamageReport.HEATING]: 'Обогрев',
  [StandardizedDamageReport.TV]: 'телевидение',
  [StandardizedDamageReport.TABLES]: 'Таблицы',
  [StandardizedDamageReport.CHROMECAST]: 'Chromecast',
};

const teamNames = {
  [Team.HOUSE_KEEPING]: 'Команда HSK',
  [Team.MAINTENANCE]: 'Команда технического обслуживания',
  [Team.OPERATIONS]: 'Операционная команда',
  [Team.ICT]: 'ICT',
};

const damageReportAreas = {
  [DamageReportArea.KITCHEN]: 'Кухня',
  [DamageReportArea.BED]: 'Кровать',
  [DamageReportArea.BATHROOM]: 'Ванная комната',
  [DamageReportArea.SEATING_AREA]: 'Гостиная',
  [DamageReportArea.WALLS_CEILING_FLOOR]: 'Стены / потолок / пол',
  [DamageReportArea.ICT]: 'ICT',
};

const damageReportActionsRequired = {
  [DamageReportActionRequired.CLEAN]: 'Чистый',
  [DamageReportActionRequired.REPLACE]: 'Заменять',
  [DamageReportActionRequired.REPAIR]: 'Ремонт',
  [DamageReportActionRequired.CHECK]: 'Проверять',
  [DamageReportActionRequired.OTHER]: 'Другой',
};

const taskPriorities = {
  [TaskPriority.LOW]: 'Стандарт',
  [TaskPriority.HIGH]: 'Высокий',
};

const popupNotifications = {
  taskDeletionNotification: 'Задание было успешно удалено',
  lostAndFoundDeletionNotification: 'Потерянный и найденный предмет успешно удален',
  damageReportDeletionNotification: 'Отчет об ущербе успешно удален',
  taskCreationNotification: 'Задание успешно создано',
  lostAndFoundCreationNotification: 'Потерянный и найденный предмет успешно создан',
  damageReportCreationNotification: 'Отчет о повреждениях успешно создан',
  taskCompletionNotification: 'Задание успешно выполнено',
  lostAndFoundCompletionNotification: 'Потерянный и найденный предмет успешно удален',
  damageReportCompletionNotification: 'Отчет о повреждениях успешно завершен',
  taskUpdateNotification: 'Задание успешно обновлено',
  lostAndFoundUpdateNotification: 'Потерянный и найденный предмет успешно обновлен',
  damageReportUpdateNotification: 'Отчет о повреждениях успешно обновлен',
  pleaseSelectRoomsNotification: 'Пожалуйста, выберите номера',
  noShowReportNotification: 'Сообщение о том, что в номер {{number}} не было заезда, успешно отправлено.',
  checkInReportNotification: 'Check in успешно зарегистрирован для комнаты {{number}}',
  damageReportWorkingTimeScheduledNotification: 'Отчет о повреждениях успешно запланирован на {{date}}',
  hskDelayNotification: 'Успешная задержка HSK для комнаты {{number}}. Время заезда обновлено до {{time}}',
};

const cleaningInterval = {
  next: 'Next cleaning',
  interval: 'Cleaning interval',
  timeRange: 'Time range',
  lastCleaning: 'Last cleaning',
  stayoverCleaning: 'Stayover cleaning',
  [CleaningInterval.WEEKLY_OR_END]: 'Weekly or end of stay',
  [CleaningInterval.BIWEEKLY]: 'Biweekly',
  [CleaningInterval.DAILY]: 'Daily',
  [CleaningInterval.NONE]: 'None',
  [CleaningInterval.EVERY_4_DAYS]: 'Every 4 days',
};

export default {
  ...teamNames,
  standardizedDamageReports,
  damageReportAreas,
  damageReportActionsRequired,
  taskPriorities,
  popupNotifications,
  cleaningInterval,
  moreFilters: 'Більше фільтрів',
  resetAllFilters: 'Сбросить все фильтры',
  reset: 'Сбросить',
  cleaned: 'Убрано',
  allRoomsCleaned: `Все номера убраны`,
  dirty: 'Грязно',
  readyToClean: 'Готов к уборке',
  stayoverReadyToClean: 'пребывание - готов к уборке',
  occupiedCleaningToday: 'Занято - уборка сегодня',
  readyToInspect: 'Готово к проверке',
  stayoverInspection: 'Проверка пребывания',
  vacant: 'Свободные',
  occupied: 'Занято',
  clean: 'Уборка ',
  daily: 'Ежедневно',
  weekly: 'Еженедельно',
  biweekly: 'Каждые две недели ',
  monthly: 'Ежемесячно',
  none: 'Отсутствует',
  tasks: 'Задачи',
  notifications: 'Уведомления',
  more: 'Больше',
  select: 'Выберите',
  property: 'Отель',
  result: 'Результат',
  results: 'Результаты',
  properties: 'Недвижимости / Отели',
  rooms: 'Комнаты',
  team: 'Команда',
  dueDate: 'Дата выполнения',
  date: 'Дата',
  close: 'Закрыть',
  search: 'Поиск',
  login: 'Вход в систему',
  loading: 'Загрузка...',
  anErrorHasOccurred: 'Произошла ошибка',
  chooseDate: 'Выбрать дату',
  selectDate: 'Выберите дату',
  selectADate: 'Выберите дату',
  markAsClean: 'Отметить как чистый',
  markAsReadyToInspect: 'Отметить как готовый к проверке',
  viewTasks: 'Просмотр задач',
  eci: 'ECI',
  lco: 'LCO',
  snacks: 'SNACKS',
  roomLog: `Журнал номера`,
  taskLog: `Журнал задач`,
  seeDetails: 'Посмотреть детали',
  assignedTasks: 'Назначенные задания',
  delete: 'Удалить',
  deleteTask: 'Удалить задание',
  deleteTaskConfirmationMessage: 'Вы уверены, что хотите удалить эту задачу?',
  deleteTaskRepeatsConfirmationMessage:
    'Это повторяющееся задание. Вы можете удалить только эту или все повторяющиеся будущие задачи.',
  deleteOnlyThisTask: 'Удалить только эту задачу',
  deleteRepeatingTask: 'Удалить повторяющуюся задачу',
  yes: 'Да',
  no: 'Нет',
  pleaseWaitAFewSeconds: 'Пожалуйста, подождите несколько секунд',
  deleting: 'Удаление',
  lostAndFound: 'Потерянные и найденные вещи',
  current: 'Текущий',
  archive: 'Архив',
  edit: 'Редактировать',
  markAsResolved: 'Отметить как решенное',
  markAsUnresolved: 'Отметить как нерешенное',
  add: 'Добавить',
  area: 'Область',
  addTask: 'Добавить задачу',
  editTask: 'Редактировать задачу',
  seeTaskDetails: 'Увидеть задание',
  savePicturesAndStartCleaning: 'Сохранить и начать уборку.',
  savePictures: 'Сохранить изображения',
  takePhoto: 'Сделать фото',
  addArea: 'Добавить область',
  addAreaDescription: 'Добавьте часть отеля для этой задачи',
  selectProperty: 'Выбрать недвижимость/отель',
  selectRooms: 'Выбрать номера',
  selectTeam: 'Выбрать команду',
  selectDueDate: 'Выбрать дату',
  hskTeam: 'Команда HSK',
  save: 'Сохранить',
  actionIsRequired: `Требуется действие. Удалите заголовок и выберите действие из списка.`,
  noActionSelected: `Действие не выбрано`,
  changeSearchAction: `Попробуйте изменить свой поисковый запрос`,
  noActionFound: `Действие не найдено`,
  selectActionHelp: `Не можете найти действие? Пожалуйста, свяжитесь с командой Операций, чтобы они могли запросить его.`,
  writeToSelectAction: 'Напишите, чтобы выбрать действие',
  writeTask: 'Написать задание',
  repetition: 'Повторение',
  noRepetition: 'Нет повторения',
  repetitionTime: 'Время повторения',
  doesNotRepeat: 'Не повторяется',
  day: 'День',
  week: 'Неделя',
  month: 'Месяц',
  repeatOn: 'Повторяется',
  repeatsEvery: 'Повторяется каждый',
  description: 'Описание',
  title: 'Заголовок',
  addTitle: 'Добавить заголовок',
  titleIsRequired: 'Требуется заголовок',
  titleMaxLength: 'Заголовок должен быть менее {{maxLength}} символов',
  teamIsRequired: 'Требуется команда',
  openTasks: 'Открытые задания',
  arrival: 'Прибытие',
  departure: 'Отъезд',
  checkIn: 'Регистрация',
  times: 'Время',
  guests: 'Гости',
  name: 'Имя',
  additionalInfo: 'Дополнительная информация',
  addLostAndFound: 'Добавить потерянное и найденное',
  addDamageReport: 'Добавить отчет об ущербе',
  unitIsRequired: 'Требуется устройство',
  addItem: 'Добавить предмет',
  editItem: 'Редактировать предмет',
  startCleaning: 'Начать уборку',
  endCleaning: 'Закончить уборку',
  endCleaningMessage: 'Есть 1 открытое задание. Вы уверены, что хотите закончить уборку?',
  endCleaningMessage_plural: 'Есть {{count}} открытых задания. Вы уверены, что хотите закончить уборку?',
  cancelAndViewTasks: 'Отменить и просмотреть задания',
  ignoreAndMarkAsReadyToInspect: 'Проигнорировать и отметить как готовые к проверке',
  ignoreAndMarkAsCleaned: 'Игнорировать и отметить как чистые',
  damageReports: 'Отчеты об ущербе',
  confirm: 'Подтвердить',
  selectRoomOrArea: 'Выберите помещение/зону или',
  createNewOne: 'создать новую',
  today: 'Сегодня',
  tomorrow: 'Завтра',
  noTasksMessage: 'Ни одно задание не было добавлено',
  cleaningStatus: 'Уборка',
  logOut: 'Выйти из системы',
  language: 'Язык',
  selectLanguage: 'Выберите язык',
  isStandardized: 'Стандартизирован?',
  selectStandardizedTask: 'Выберите стандартизированное задание',
  overdue: 'Просроченные',
  arrivals: 'Прибытия',
  memberArrivals: 'Member arrivals',
  departures: 'Отъезды',
  stayovers: 'Уборка в середине пребывания',
  occupancy: 'Заполненность OTB',
  midstayCleaning: 'Уборка в середине пребывания',
  roomsToSell: 'Номера на продажу',
  editImages: 'Редактировать изображения',
  toClean: 'убирать',
  toInspect: 'проверить',
  noRoomsForCleaningToday: 'Сегодня нет комнат для уборки',
  noShow: 'Отсутствие заезда',
  noShowReportConfirmationMessage: 'Пожалуйста, подтвердите, что в номер {{number}} не было заезда.',
  noShowReportQuestion: 'Гость заселился вчера?',
  checkInReportConfirmationMessage: 'Подтвердите, что гость зарегистрировался в номере {{number}}.',
  noResultsSearchMessage: 'Нет результатов, соответствующих вашим критериям поиска',
  cancel: 'Отменить',
  selectCleaner: 'Выберите уборщика',
  selectAll: 'Выбрать все',
  floor: 'Этаж',
  notAssigned: 'Не назначено',
  assignXRooms: 'Назначить {{всего}} номеров',
  assignRooms: 'Назначить комнаты',
  approvedLCOs: 'Утвержденные LCO',
  cleaner: 'Очиститель',
  roomsCleanForToday: 'Все комнаты на сегодня чистые!',
  viewDamageReports: 'Просмотр отчетов о повреждениях',
  noTasks: 'Нет задач',
  noDamageReports: 'Нет отчетов о повреждениях',
  dailyCleaningReport: 'Отчет о ежедневной уборке',
  stayover: 'Остаться',
  roomMoveFrom: 'Перемещение комнаты из {{value}}',
  roomMoveTo: 'Перемещение комнаты в {{value}}',
  unassignAll: 'Отменить назначение всех',
  unassignRoomsConfirmationMessage: 'Вы действительно хотите отменить назначение {{total}} комнат?',
  unassignRoomsTitle: 'Отменить назначение комнат',
  noRoomAssigned: 'Комната не назначена',
  action: 'Действие',
  hideActions: 'Скрыть действия',
  showActions: 'Показать действия',
  actionsSelected: '{{total}} Aktion(en) ausgewählt',
  disableActionTitleModal: 'Сбросить фильтр {{name}}',
  disableActionDescriptionModal: 'Для использования фильтра {{currentFilter}} сбросьте фильтр {{resetFilter}}.',
  actionRequired: 'Требуются действия',
  priority: 'Приоритет',
  lcoUntil: 'LCO до',
  created: 'Созданный',
  startNow: 'Начинай сейчас',
  scheduleTime: 'График времени',
  viewInfo: 'Просмотр информации',
  reminder: 'Напоминание',
  reminderTaskNotificationText: 'Ваша запланированная задача должна быть выполнена сегодня',
  inProgress: 'В ходе выполнения',
  scheduled: 'по расписанию',
  moveMidstayCleaning: 'Переместить остаточную уборку?',
  moveMidstayTitle: 'Move Midstay ',
  moveMidstayDescription: 'Вы действительно хотите перенести промежуточную уборку на {{date}}?',
  moveMidstayNotPossible: 'Прости! Промежуточную уборку этого номера нельзя перенести на другой день!',
  moveMidstayNotification: 'Остаточная очистка успешно перенесена на {{date}}.',
  pleaseContactOps: 'Пожалуйста, свяжитесь с командой эксплуатации',
  midCleanMovedTo: 'Остаточная уборка перенесена на: {{value}}',
  optional: 'по желанию',
  hskDelay: 'Задержка HSK',
  hskDelays: 'Задержки HSK',
  hskDelayMessage: 'Обновите время заезда для комнаты {{number}}',
  notStarted: 'Не начато',
  resolved: 'Решено',
  noDamageReportsToday: 'Сообщений о повреждениях на сегодня нет',
  yourTask: 'Твое задание',
  yourTaskGX: 'Ваша задача от GX',
  damageReportedByGX: 'Повреждение, о котором сообщает GX',
  stay: 'Даты пребывания',
  checkOut: 'Время выселения.',
  noPermission: 'У вас нет разрешения, пожалуйста, свяжитесь с поддержкой.',
  approvedCheckoutTime: 'Утвержденное время выезда',
  requestedCheckoutTime: 'Запрошенное время выезда',
  checkinTime: 'Время регистрации',
  commentForHousekeeping: 'Комментарий для горничных',
  numberOfApprovedLCOs: 'Количество одобренных ЛСО',
  notSet: 'Не установлено',
  vip: 'ВИП',
  extraService: 'Дополнительное обслуживание',
  reservationMissing: 'Бронирование, скорее всего, отменено, переключитесь на вкладку "Общая" вместо этого.',
  confirmFor: 'Подтвердить на {{date}}',
  confirmAnotherDay: 'Подтвердить на другой день',
  taskConfirmed: 'Вы подтвердили задание на {{date}}',
  confirmYourTask: 'Подтвердите свою задачу',
  confirmYourTaskGX: 'Подтвердите свою задачу от GX',
  confirmHighPriorityTaskMessage:
    'Вы действительно хотите установить срок выполнения на сегодня? Вероятно, задача не будет подтверждена и завершена сегодня.',
  setTomorrow: 'Установите на завтра',
  keepToday: 'Сохраните сегодня',
  createTask: 'Создать задачу',
  confirmationNeeded: 'Подтверждение требуется',
  pictureTooBlurry: `Изображение слишком размыто. Пожалуйста, сделайте четкое фото и загрузите его.`,
  tryAgain: `Попробуйте снова`,
  addDescription: `Добавить описание`,
  togglePushNotificationsLabel: `Уведомления на iOS`,
  roomRack: `Номера`,
  newSlot: `Новый слот`,
  searchByRoomNumber: `Номер комнаты`,
  reservation: `Бронирование`,
  outOfOrder: `Out of Order`,
  outOfService: `Out of Service`,
  outOfInventory: `Out of Inventory`,
  slotType: `Тип слота`,
  selectType: `Выберите тип`,
  reason: `Причина`,
  selectReason: `Выберите причину`,
  selectDateAndTime: `Дата и время`,
  startDate: `Дата начала`,
  startTime: `Время начала`,
  endDate: `Дата окончания`,
  endTime: `Время окончания`,
  room: `Номер`,
  selectRoom: `Выберите номер`,
  cleanAfterBlock: `Убрать после окончания`,
  addSlot: `Добавить слот`,
  updateSlot: `Обновить слот`,
  errorStartDatePastSlotForm: `Дата начала не может быть в прошлом`,
  errorEndDatePastSlotForm: `Дата окончания не может быть раньше даты начала`,
  errorEndTimePastSlotForm: `Время окончания не может быть раньше времени начала`,
  errorMinDurationSlotForm: `Минимальная продолжительность слота - 30 минут`,
  errorOverbookedSlotForm: `Слот может привести к переполнению в выбранные дни. Создайте его только в случае крайней необходимости, в противном случае выберите другой временной период.`,
  outOfOrderInfo: `OOO - Out of Order. Следует использовать, если номер не функционирует и не может быть использован гостем.`,
  outOfServiceInfo: `OOS - Out of Service. Позволяет продавать номер. Номер, отмеченный как Out of Service, полностью функционален и имеет только незначительные косметические дефекты.`,
  outOfInventoryInfo: `OOI - Out of Inventory. Используется командой Launch для долгосрочных планов обслуживания, таких как ремонт всего этажа.`,
  maintenanceReason: `Обслуживание`,
  cleaningIssueReason: `Проблема с уборкой`,
  roomMoveBlockReason: `Перемещение номера`,
  bedBugsReason: `Клопы`,
  smokingReason: `Курение`,
  vipGuestReason: `Гость VIP`,
  pmpReason: `PMP`,
  rampUpSruReason: `Ramp-up: стратегический`,
  rampUpRnrReason: `Ramp-up: комната не готова`,
  hoReason: `Оптимизация HSK`,
  othersReason: `Другая причина`,
  createdInApaleo: `Создано в Apaleo`,
  reasonNotApaleo: `\`Создано в Apaleo\` - не является допустимой причиной. Выберите другую.`,
  quarantineReason: `Карантин`,
  slotLog: `Log`,
  deleteSlot: `Удалить слот`,
  deleteSlotLabel: `Пожалуйста, укажите причину удаления`,
  errorDeleteSlot: `Вы не можете удалить слот.`,
  errorDeleteInfoSlot: `Текущий слот не может быть удалён, вместо этого его можно сократить.`,
  fieldRequired: `{{field}}: поле обязательно`,
  from: `с `,
  until: `до `,
  issueResolveDeleteReason: `Проблема решена / слот не нужен`,
  createdByMistakeDeleteReason: `Слот создан по ошибке`,
  guestRejectedMoveDeleteReason: `Гость отказался переехать в другую комнату`,
  deleteReasonIsRequired: `Причина удаления обязательна`,
  editSlot: `Редактировать слот`,
  earlyCheckinByHour: `ECI - уборка к {{hour}}:00`,
};
