import { useEffect, useMemo, useState } from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import { XIcon } from '@heroicons/react/solid';
import { useForm, Controller } from 'react-hook-form';
import { addDays, format } from 'date-fns';
import usePropertiesState from '@context/propertiesContext';

import Spinner from '@atoms/Spinner';
import TextArea from '@molecules/TextArea';
import { Selector } from './components/Selector';
import { DateSelector } from './components/DateSelector';
import { TimeSelector } from './components/TimeSelector';
import { ErrorInfo } from './components/ErrorInfo';
import { SettingsSlotSidebar } from './components/Settings';
import { AutocompleteSimpleInput } from '@molecules/AutocompleteSimpleInput';

import { allReasonsSlotIncludingNotValid, defaultMinMaxDate, defaultOptions } from './constants';
import { formattedOption, checkDateFormValidity, showFormErrors, clearFormErrors } from './utils';
import useCreateOrUpdateSlotRoomRack from '@utils/hooks/useCreateOrUpdateRoomRack';
import useCheckAvailabilityOverbookingSlot from '@utils/hooks/useCheckAvailabilityOverbooking';

import { APALEO_MAINTENANCE_SLOT_REASON, SlotItem } from '../RoomRackTable/types';
import { MaintenanceSlotType, UpdateSlotFormRequest } from '@typings/types';
import './styleSidebar.css';
import { combineDateAndTime, getNextHourDefault, setTimeDateToPropertyTimezone } from '@utils/dateUtils';

interface RoomSlotSidebarProps {
  slotSelected: SlotItem | null;
  onClose: () => void;
  rooms: {
    id: string;
    title: string;
  }[];
  dateRange: {
    start: Date;
    end: Date;
  };
}

export type SlotForm = {
  type: string;
  reason: string;
  unitId: string;
  startDate: Date;
  startTime: string;
  endDate: Date;
  endTime: string;
  description: string;
  endWithCleaning: boolean;
};

export const RoomSlotSidebar = ({ slotSelected, rooms, dateRange, onClose }: RoomSlotSidebarProps) => {
  const { t } = useTranslation();
  const { selectedProperty } = usePropertiesState();
  const todayPropertyTimezone = utcToZonedTime(new Date(), selectedProperty.timeZone);

  const [isSubmitButtonVisible, setIsSubmitButtonVisible] = useState<boolean>(!slotSelected);
  const [editedValues, setEditedValues] = useState<Partial<SlotForm> | null>(null);
  const [warningOverbooking, setWarningOverbooking] = useState<string | null>(null);
  const [isFormApiError, setIsFormApiError] = useState<string | null>(null);
  const [isOverbookingApiError, setIsOverbookingApiError] = useState<string | null>(null);

  const { submitCreateSlotForm, submitUpdateSlotForm, isSubmitting } = useCreateOrUpdateSlotRoomRack({
    property: selectedProperty,
    dateRange,
    onSuccess: () => {
      setIsFormApiError(null);
      onClose?.();
    },
    onError: (error) => {
      setIsFormApiError(error?.message);
    },
  });

  const defaultFormValues = useMemo(() => {
    if (slotSelected) {
      const startDate = new Date(slotSelected.start_time);
      const endDate = new Date(slotSelected.end_time);
      const roomId = slotSelected?.group;
      setIsSubmitButtonVisible(false);

      return {
        startDate,
        endDate,
        startTime: format(startDate, 'HH:mm'),
        endTime: format(endDate, 'HH:mm'),
        unitId: roomId as string,
        type: slotSelected.type,
        description: slotSelected.description,
        endWithCleaning: slotSelected.cleanAfterBlock,
        reason: slotSelected.reason,
      };
    } else {
      setIsSubmitButtonVisible(true);

      return {
        type: '',
        reason: '',
        unitId: '',
        startDate: todayPropertyTimezone,
        endDate: addDays(todayPropertyTimezone, 1),
        startTime: getNextHourDefault(todayPropertyTimezone),
        endTime: '11:00',
        description: '',
        endWithCleaning: true,
      };
    }
  }, [slotSelected]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    watch,
    reset,
  } = useForm<SlotForm>({
    defaultValues: defaultFormValues,
    mode: 'onChange',
  });

  const typeWatch = watch('type');
  const unitIdWatch = watch('unitId');
  const startDateWatch = watch('startDate');
  const startTimeWatch = watch('startTime');
  const endDateWatch = watch('endDate');
  const endTimeWatch = watch('endTime');

  const { refetch, error: overbookingError } = useCheckAvailabilityOverbookingSlot({
    propertyId: selectedProperty.id,
    unitId: unitIdWatch,
    fromDate: setTimeDateToPropertyTimezone(
      combineDateAndTime(startDateWatch, startTimeWatch),
      selectedProperty.timeZone,
    ),
    toDate: setTimeDateToPropertyTimezone(combineDateAndTime(endDateWatch, endTimeWatch), selectedProperty.timeZone),
    slotId: slotSelected?.id as string,
  });

  useEffect(() => {
    if (overbookingError) {
      setIsOverbookingApiError((overbookingError as Error)?.message);
    } else {
      setIsOverbookingApiError(null);
    }
  }, [overbookingError]);

  function checkAvailabilityOverbooking() {
    refetch().then(({ data: dataRes }) => {
      if (dataRes?.available) {
        setWarningOverbooking(null);
      } else {
        setWarningOverbooking(t('errorOverbookedSlotForm'));
      }
    });
  }

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (slotSelected) {
        setEditedValues((prevEditedValues) => ({
          ...prevEditedValues,
          [name as string]: values[name as keyof SlotForm],
        }));
        setIsSubmitButtonVisible(true);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, slotSelected]);

  useEffect(() => {
    if (
      (typeWatch === MaintenanceSlotType.OUT_OF_ORDER || typeWatch === MaintenanceSlotType.OUT_OF_INVENTORY) &&
      unitIdWatch &&
      startDateWatch &&
      startTimeWatch &&
      endDateWatch &&
      endTimeWatch
    ) {
      checkAvailabilityOverbooking();
    } else {
      setWarningOverbooking(null);
    }
  }, [typeWatch, unitIdWatch, startDateWatch, startTimeWatch, endDateWatch, endTimeWatch]);

  useEffect(() => {
    reset(defaultFormValues);
    setIsSubmitButtonVisible(!slotSelected);
    setEditedValues(null);
  }, [slotSelected]);

  const onSubmit = async (data: SlotForm) => {
    const { startDate, startTime, endDate, endTime } = data;

    const startDateFormatted = combineDateAndTime(startDate, startTime);
    const endDateFormatted = combineDateAndTime(endDate, endTime);

    const { isValidDate, formDateErrors } = checkDateFormValidity(
      todayPropertyTimezone,
      startDateFormatted,
      endDateFormatted,
      t,
    );

    if (editedValues) {
      let formattedEditedDate = {};
      const {
        startDate: editedStartDate,
        endDate: editedEndDate,
        startTime: editedStartTime,
        endTime: editedEndTime,
      } = editedValues;

      if (editedStartDate || editedEndDate || editedStartTime || editedEndTime) {
        if (!isValidDate) {
          showFormErrors(formDateErrors, setError);
          return;
        } else {
          clearFormErrors(formDateErrors, clearErrors);
        }
      }

      if (editedStartDate || editedStartTime) {
        if (!isValidDate) return;
        formattedEditedDate = {
          ...formattedEditedDate,
          from: setTimeDateToPropertyTimezone(startDateFormatted, selectedProperty.timeZone),
        };
      }
      if (editedEndDate || editedEndTime) {
        if (!isValidDate) return;
        formattedEditedDate = {
          ...formattedEditedDate,
          to: setTimeDateToPropertyTimezone(endDateFormatted, selectedProperty.timeZone),
        };
      }

      const formattedSlotEditedForm: UpdateSlotFormRequest = Object.fromEntries(
        Object.entries({ ...editedValues, ...formattedEditedDate }).filter(
          ([key]) =>
            key !== 'startTime' &&
            key !== 'endTime' &&
            key !== 'startDate' &&
            key !== 'endDate' &&
            key !== 'unitId' &&
            key !== 'type',
        ),
      );

      await submitUpdateSlotForm(slotSelected?.id as string, formattedSlotEditedForm);
    } else {
      if (!isValidDate) {
        showFormErrors(formDateErrors, setError);
        return;
      } else {
        clearFormErrors(formDateErrors, clearErrors);
      }
      await submitCreateSlotForm({
        from: setTimeDateToPropertyTimezone(startDateFormatted, selectedProperty.timeZone),
        to: setTimeDateToPropertyTimezone(endDateFormatted, selectedProperty.timeZone),
        description: data.description,
        endWithCleaning: data.endWithCleaning,
        reason: data.reason,
        type: data.type,
        unitId: data.unitId,
        system: 'system',
      });
    }
  };

  const options = useMemo(() => ({ ...defaultOptions, rooms: formattedOption(rooms) }), [rooms]);

  const formatReasonsOptions = (reasons: { translationKey: string; value: string }[]) => {
    return reasons.map(({ translationKey, value, ...props }) => ({
      label: `${value} - ${t(translationKey)}`,
      value: value,
      ...props,
    }));
  };

  return (
    <div className="fixed right-0 top-0 z-50 h-screen bg-white w-72 font-sans flex flex-col shadow-lg rounded-2xl">
      <div className="w-full flex justify-between items-center mt-2 md:mt-8 p-4 border-b border-b-th-brown-50">
        <div className="flex justify-start items-center gap-x-4">
          <button
            type="button"
            className="bg-transparent rounded-md text-black hover:text-gray-500 focus:outline-none"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="text-base font-bold font-serif">{slotSelected ? t('editSlot') : t('newSlot')}</div>
        </div>
        {slotSelected && (
          <SettingsSlotSidebar selectedProperty={selectedProperty} dateRange={dateRange} slotItem={slotSelected} />
        )}
      </div>
      <div className="overflow-y-auto">
        <form onSubmit={handleSubmit(onSubmit)} className="p-6 flex flex-col gap-y-4">
          <Controller
            name={'type'}
            control={control}
            rules={{ required: t('fieldRequired', { field: t('slotType') }) }}
            render={({ field }) => (
              <Selector
                label={t('slotType')}
                isDisabled={!!slotSelected}
                ContentInfoText={() => (
                  <div className="flex flex-col justify-start items-start gap-y-3">
                    <div>{t('outOfOrderInfo')}</div>
                    <div>{t('outOfServiceInfo')}</div>
                    <div>{t('outOfInventoryInfo')}</div>
                  </div>
                )}
                placeholder={t('selectType')}
                options={options['slotType']}
                error={errors['type']?.message}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            name={'reason'}
            control={control}
            rules={{
              required: t('fieldRequired', { field: t('reason') }),
              validate: (value) => value !== APALEO_MAINTENANCE_SLOT_REASON || t('reasonNotApaleo'),
            }}
            render={({ field }) => (
              <Selector
                label={t('reason')}
                placeholder={t('selectReason')}
                options={formatReasonsOptions(
                  slotSelected?.reason === APALEO_MAINTENANCE_SLOT_REASON
                    ? allReasonsSlotIncludingNotValid
                    : options['reason'],
                )}
                error={errors['reason']?.message}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <div className="flex flex-col justify-center items-start gap-y-4">
            <div>
              <span className="text-th-brown-300 font-semibold text-xs leading-4">{t('selectDateAndTime')}</span>
            </div>
            <div className="w-full">
              <div className="flex justify-between items-start gap-x-2 w-full">
                <Controller
                  name="startDate"
                  control={control}
                  rules={{
                    required: t('fieldRequired', { field: t('startDate') }),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <DateSelector
                      minDate={addDays(todayPropertyTimezone, -defaultMinMaxDate)}
                      maxDate={addDays(todayPropertyTimezone, defaultMinMaxDate)}
                      label={t('startDate')}
                      onChangeDate={onChange}
                      value={value}
                      error={undefined}
                    />
                  )}
                />
                <Controller
                  name="startTime"
                  control={control}
                  rules={{ required: t('fieldRequired', { field: t('startTime') }) }}
                  render={({ field: { onChange, value } }) => (
                    <TimeSelector label={t('startTime')} onChangeTime={onChange} value={value} error={undefined} />
                  )}
                />
              </div>
              <div className="flex justify-between items-start gap-x-2">
                <div className="w-3/5">
                  {errors['startDate']?.message && (
                    <p className="mt-1 text-red-500 text-xs">{errors['startDate']?.message}</p>
                  )}
                </div>
                <div className="w-2/5">
                  {errors['startTime']?.message && (
                    <p className="mt-1 text-red-500 text-xs">{errors['startTime']?.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className={`flex justify-between items-end gap-x-2 w-full`}>
                <Controller
                  name="endDate"
                  control={control}
                  rules={{
                    required: t('fieldRequired', { field: t('endDate') }),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <DateSelector
                      minDate={addDays(todayPropertyTimezone, -defaultMinMaxDate)}
                      maxDate={addDays(todayPropertyTimezone, defaultMinMaxDate)}
                      label={t('endDate')}
                      onChangeDate={onChange}
                      value={value}
                      error={errors['endDate']?.message}
                    />
                  )}
                />
                <Controller
                  name="endTime"
                  control={control}
                  rules={{ required: t('fieldRequired', { field: t('endTime') }) }}
                  render={({ field: { onChange, value } }) => (
                    <TimeSelector
                      label={t('endTime')}
                      onChangeTime={onChange}
                      value={value}
                      error={errors['endTime']?.message}
                    />
                  )}
                />
              </div>
              <div className="flex justify-between items-start gap-x-2">
                <div className="w-3/5">
                  {errors['endDate']?.message && (
                    <p className="mt-1 text-red-500 text-xs">{errors['endDate']?.message}</p>
                  )}
                </div>
                <div className="w-2/5">
                  {errors['endTime']?.message && (
                    <p className="mt-1 text-red-500 text-xs">{errors['endTime']?.message}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Controller
            name={'unitId'}
            control={control}
            rules={{ required: t('fieldRequired', { field: t('room') }) }}
            render={({ field }) => (
              <AutocompleteSimpleInput
                label={t('room')}
                placeholder={t('selectRoom')}
                isDisabled={!!slotSelected}
                options={options['rooms']}
                error={errors['unitId']?.message}
                value={field.value}
                onChange={field.onChange}
                buttonClassName={`flex justify-between items-center border ${
                  errors['unitId'] ? 'border-th-red-200' : 'border-th-brown-100'
                } ${!!slotSelected && 'border-th-brown-100 bg-th-gray-250 cursor-default'} rounded-[4px] p-2 w-full`}
              />
            )}
          />
          <Controller
            name={'endWithCleaning'}
            control={control}
            render={({ field }) => (
              <label
                htmlFor="endWithCleaning"
                className="cursor-pointer font-semibold text-sm leading-[18px] flex justify-start items-center gap-x-2"
              >
                <input
                  id="endWithCleaning"
                  type="checkbox"
                  value={'field.value'}
                  checked={field.value}
                  onChange={field.onChange}
                />
                {t('cleanAfterBlock')}
              </label>
            )}
          />
          {warningOverbooking && <ErrorInfo message={warningOverbooking} />}
          <Controller
            name={'description'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className="bg-gray-50 rounded-md">
                <div className={'bg-white rounded-md m-2 text-sm'}>
                  <TextArea
                    value={value}
                    onChange={onChange}
                    placeholder={t('addDescription')}
                    error={errors.description}
                  />
                </div>
              </div>
            )}
          />
          {isSubmitButtonVisible && (
            <button
              type="submit"
              className="bg-th-secondary text-white font-normal flex justify-center items-center text-base py-4 rounded-[6px] hover:opacity-90"
            >
              {!isSubmitting ? (
                slotSelected ? (
                  t('updateSlot')
                ) : (
                  t('addSlot')
                )
              ) : (
                <Spinner className="w-5 h-5 text-th-primary" />
              )}
            </button>
          )}
          {isFormApiError && (
            <div className="w-full">
              <p className="text-red-500 text-xs">{isFormApiError}</p>
            </div>
          )}
          {isOverbookingApiError && (
            <div className="w-full">
              <p className="text-red-500 text-xs">{isOverbookingApiError}</p>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
