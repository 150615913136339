import { ChangeEvent, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

import { SVGIconType } from '@typings/types';

type Option = {
  label: string;
  value: string;
  icon?: SVGIconType;
};

interface AutocompleteSimpleInputProps {
  label?: string;
  placeholder: string;
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  error?: string;
  InputIcon?: () => JSX.Element;
  buttonClassName?: string;
  inputClassName?: string;
  chevronClassName?: string;
  optionsClassName?: string;
}

export const AutocompleteSimpleInput = ({
  label,
  placeholder,
  options,
  value,
  isDisabled = false,
  onChange,
  error,
  InputIcon,
  buttonClassName,
  inputClassName,
  chevronClassName = 'h-5 w-5',
  optionsClassName,
}: AutocompleteSimpleInputProps) => {
  const [filteredValue, setFilteredValue] = useState<string>('');

  const filteredOption =
    filteredValue === '' ? options : options.filter((option) => option.label.includes(filteredValue));

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (newValue === '') {
      onChange('');
      setFilteredValue('');
    } else {
      setFilteredValue(e.target.value);
    }
  };

  return (
    <div>
      {label && <span className="font-sans text-th-brown-300 font-semibold text-xs leading-4">{label}</span>}
      <Combobox value={value} onChange={onChange} disabled={isDisabled}>
        {({ open }) => {
          if (!open) setFilteredValue('');
          return (
            <>
              <Combobox.Button className={buttonClassName}>
                {InputIcon && <InputIcon />}
                <Combobox.Input
                  onChange={handleInputChange}
                  className={`block truncate text-th-brown-300 border-none focus:ring-0 p-0 bg-transparent ${inputClassName}`}
                  placeholder={value ? options.find((option) => option.value === value)?.label : placeholder}
                  value={value}
                  displayValue={(item) => options.find((option) => option.value === item)?.label || ''}
                />
                {!isDisabled && <ChevronDownIcon className={chevronClassName} />}
              </Combobox.Button>
              <Combobox.Options
                className={`z-[60] w-full py-1 max-h-96 overflow-auto text-base bg-white rounded-md shadow-lg 
                  ring-black ring-opacity-5 focus:outline-none sm:text-sm ${optionsClassName}`}
              >
                {filteredOption.map((option) => {
                  const isOptionChecked = value === option.value;

                  return (
                    <Combobox.Option
                      key={option.value}
                      value={option.value}
                      onClick={() => {
                        setFilteredValue('');
                        onChange(option.value);
                      }}
                      className={`cursor-pointer relative p-4 hover:bg-th-brown-50 focus:bg-th-light-green ${
                        isOptionChecked && 'bg-th-light-green'
                      }`}
                    >
                      <div className="flex justify-start items-center gap-x-2 text-th-brown font-medium text-base">
                        {option.icon && <option.icon />}
                        <span className={`block truncate`}>{option.label}</span>
                      </div>
                    </Combobox.Option>
                  );
                })}
              </Combobox.Options>
            </>
          );
        }}
      </Combobox>
      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </div>
  );
};
