import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useDeleteSlotRequestRoomRack } from '@api/api';
import { useToggle } from '@utils/hooks/useToggle';

import { ServerStateKey } from '@typings/enums';
import { DeleteSlotFormRequest, PropertyType } from '@typings/types';

interface Props {
  property: Pick<PropertyType, 'id' | 'timeZone'>;
  onSuccess?: () => void;
  onError?: () => void;
  dateRange: {
    start: Date;
    end: Date;
  };
}

export default function useDeleteSlotRoomRack({ property, dateRange, onSuccess, onError }: Props) {
  const [isSubmitting, setSubmitting] = useToggle(false);
  const queryClient = useQueryClient();

  const propertyId = property.id;

  const deleteSlotRequest = useDeleteSlotRequestRoomRack(propertyId, {
    onSettled: () => {
      setSubmitting(false);
    },
    onSuccess: () => {
      onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [ServerStateKey.ROOM_RACK, propertyId, dateRange.start.toISOString(), dateRange.end.toISOString()],
      });
    },
    onError: () => {
      onError?.();
    },
  });

  const submitDeleteSlotForm = useCallback(
    async (slotId: string, formValues: DeleteSlotFormRequest) => {
      if (isSubmitting) return;
      setSubmitting(true);

      try {
        deleteSlotRequest.mutate({ id: slotId, data: formValues });
      } catch (error) {
        setSubmitting(false);
        console.error(error);
      }
    },
    [isSubmitting],
  );

  return {
    isSubmitting,
    submitDeleteSlotForm,
  };
}
