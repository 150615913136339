import type { TimelineGroupBase, TimelineItemBase, ItemContext, Id } from 'react-calendar-timeline';
import { SlotType } from '../types';

export type DateRangeType = { start: Date; end: Date };

type DateType = Date | number;

export interface RowType extends TimelineGroupBase {
  title: string;
}

export interface SlotItem extends TimelineItemBase<DateType> {
  title: string;
  itemProps: {
    style: React.CSSProperties;
  };
  start_time: number;
  end_time: number;
  externalId?: string;
  roomNumber: string;
  stackOrder?: number;
  isEarlyCheckIn?: boolean;
  isEarlyCheckOut?: boolean;
  type: SlotType;
  cleanAfterBlock?: boolean;
  description?: string;
  reason?: allMaintenanceSlotReasons;
}

export type ItemRendererProps = {
  item: SlotItem;
  itemContext: ItemContext;
  getItemProps: (props: object) => object;
  getResizeProps: (props?: object) => object;
};

export interface RoomRackTableProps {
  onSlotClick: (itemId: Id) => void;
  roomsData: RowType[];
  slotsData: SlotItem[];
  dateRange: DateRangeType;
}

export enum MaintenanceSlotReason {
  CL = 'CL',
  MNT = 'MNT',
  BB = 'BB',
  SMK = 'SMK',
  VIP = 'VIP',
  PMP = 'PMP',
  RUSRU = 'RUSRU',
  RURNR = 'RURNR',
  HO = 'HO',
  OTHER = 'OTHER',
  RM = 'RM',
}

export const APALEO_MAINTENANCE_SLOT_REASON = 'NA_APALEO';

export type allMaintenanceSlotReasons = MaintenanceSlotReason | typeof APALEO_MAINTENANCE_SLOT_REASON;
