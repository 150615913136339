import { Dispatch, SetStateAction } from 'react';
import { addDays, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

import { DateRangeType } from '../RoomRackTable/types';
import { DEFAULT_INITIAL_DATE } from '../RoomRack';

interface DateRangeProps {
  dateRange: DateRangeType;
  setDateRange: Dispatch<SetStateAction<DateRangeType>>;
  dateRangeLength: number;
}

export const DateRangeNavigation = ({ dateRange, setDateRange, dateRangeLength }: DateRangeProps) => {
  const { t } = useTranslation();

  const onSkipToFutureDate = () => {
    setDateRange({
      start: addDays(dateRange.start, dateRangeLength),
      end: addDays(dateRange.end, dateRangeLength),
    });
  };

  const onSkipToPastDate = () => {
    setDateRange({
      start: addDays(dateRange.start, -dateRangeLength),
      end: addDays(dateRange.end, -dateRangeLength),
    });
  };

  const onResetPresentDate = () => {
    setDateRange({
      start: DEFAULT_INITIAL_DATE,
      end: addDays(DEFAULT_INITIAL_DATE, dateRangeLength),
    });
  };
  return (
    <div className="flex justify-start sm:justify-end items-center gap-x-2">
      <button
        className="border border-th-brown-100 rounded py-[6px] px-2 font-semibold hover:bg-[#EDF0F3]"
        onClick={onResetPresentDate}
      >
        {t('today')}
      </button>
      <div className="border border-th-brown-100 rounded py-[6px] px-2 flex justify-center items-center gap-x-[2px]">
        <button onClick={onSkipToPastDate} className="rounded hover:bg-th-gray-200">
          <ChevronLeftIcon width={14} height={14} />
        </button>
        <div>
          {format(dateRange.start, 'dd/MM')} ~ {format(dateRange.end, 'dd/MM')}
        </div>
        <button onClick={onSkipToFutureDate} className="rounded hover:bg-th-gray-200">
          <ChevronRightIcon width={14} height={14} />
        </button>
      </div>
    </div>
  );
};
