import {
  addDays,
  addHours,
  differenceInDays,
  endOfDay,
  format,
  isAfter,
  isBefore,
  isPast,
  isSameDay,
  set,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  subDays,
} from 'date-fns';
import {
  utcToZonedTime as fnsUtcToZonedTime,
  zonedTimeToUtc as fnsZonedTimeToUtc,
  getTimezoneOffset,
} from 'date-fns-tz';
import { WeekDay } from '@typings/types';

export function datesBetween(startDate: Date, endDate: Date): Date[] {
  const days = differenceInDays(endDate, startDate);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return [...Array(days + 1).keys()].map((i) => addDays(startDate, i));
}

export const DEFAULT_TIMEZONE = 'Europe/Berlin';

const DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_AND_TIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const MIDCLEAN_DATE_FORMAT = 'yyyy-MM-dd';
export const TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT_SHORT = 'dd.MM';
export const DATE_FORMAT_LONG = 'EEEE dd/MM';
export const REQUEST_DATE_FORMAT = 'MM-dd-yyyy';
export const DATE_AND_DAY_OF_WEEK = 'dd MMM (EEEE)';
export const DATE_ISO_NO_TZ = "yyyy-MM-dd'T'HH:mm:ss.sss";

export function formatDate(dateValue?: string | Date, dateFormat = DATE_FORMAT) {
  const date = typeof dateValue === 'string' ? new Date(dateValue) : dateValue;
  return date ? format(date, dateFormat) : '';
}

export function toDate(value: string | Date) {
  return new Date(value);
}

export function isTodayInProperty(propertyDate: Date, timeZone: string) {
  const now = nowInProperty(timeZone);
  return isSameDay(propertyDate, now);
}

export function isTomorrowInProperty(propertyDate: Date, timeZone: string) {
  const tomorrow = addDays(nowInProperty(timeZone), 1);
  return isSameDay(propertyDate, tomorrow);
}

export function getDatesBetweenDays(from: Date, to: Date): Date[] {
  const countDaysBetween = differenceInDays(to, from);
  return [...new Array(countDaysBetween)].map((_, index) => addDays(from, index));
}

export const weekDayOptions: WeekDay[] = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

export const setTime = (date: Date, time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  return setHours(setMinutes(setSeconds(setMilliseconds(date, 0), 0), minutes), hours);
};

const now = () => new Date();

const nowInProperty = (timeZone: string) => utcToZonedTime(now(), timeZone);

const zonedTimeToUtc = (date: Date | string, timeZone: string) => {
  return fnsZonedTimeToUtc(date, timeZone);
};

const utcToZonedTime = (date: Date | string, timeZone: string) => {
  return fnsUtcToZonedTime(date, timeZone);
};

const formatISO = (date: Date) => {
  return formatDate(date, DATE_ISO_NO_TZ) + 'Z';
};

const getDueAtUtc = (date: Date, timeZone: string): string => {
  return zonedTimeToUtc(date, timeZone).toISOString();
};

export function getThreeLettersAbbrevMonth(date: Date, locale: Locale) {
  return format(date, 'MMM', { locale: locale });
}

export const getNextHourDefault = (now: Date) => {
  let nextHour = addHours(setMinutes(now, 0), 1);

  // Check if the next hour is after 23:00
  if (isAfter(nextHour, endOfDay(now))) {
    nextHour = setMinutes(endOfDay(now), 59);
  }
  return format(nextHour, 'HH:mm');
};

// Removes the user timezone date and sets the date to the property timezone
// If user is GTM -5 and property is GTM +2, the date will be adjusted to GTM +2 without transforming the time
// 2024-12-02T01:00:00.000Z+07:00 -> 2024-12-02T01:00:00.000Z
export function setTimeDateToPropertyTimezone(date: Date, propertyTimezone: string): string {
  const userTimezoneOffsetInMinutes = -date.getTimezoneOffset();
  const propertyTimezoneOffsetInMinutes = getTimezoneOffset(propertyTimezone, date) / (1000 * 60);

  const adjustedTime = date.getTime() + (userTimezoneOffsetInMinutes - propertyTimezoneOffsetInMinutes) * 60000;
  const adjustedDate = new Date(adjustedTime);
  return adjustedDate.toISOString();
}

export function combineDateAndTime(startDate: Date, startTime: string) {
  const [hours, minutes] = startTime.split(':');
  return setHours(setMinutes(setSeconds(startDate, 0), Number(minutes)), Number(hours));
}

export default {
  now,
  nowInProperty,
  isPast,
  subDays,
  isAfter,
  isBefore,
  set,
  setTime,
  toDate,
  zonedTimeToUtc,
  utcToZonedTime,
  getDueAtUtc,
  formatISO,
};
