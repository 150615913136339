import { ApiInfo } from '@api/types';

const basePath = (propertyId: string) => `/${propertyId}/room-rack`;
const createAndUpdatePath = (propertyId: string) => `/${propertyId}/maintenance-slot`;
const maintenanceAvailabilityPath = (propertyId: string) => `/${propertyId}/maintenance-availability`;

const roomRackApi: ApiInfo = {
  getRoomRack: ({ params, query }) => {
    const { propertyId } = params!;
    const { fromDate, toDate } = query! as {
      fromDate?: string;
      toDate?: string;
    };

    const queryParams = new URLSearchParams();
    if (fromDate) queryParams.append('from', fromDate);
    if (toDate) queryParams.append('to', toDate);

    return {
      url: `${basePath(propertyId)}?${queryParams.toString()}`,
    };
  },
  saveOne: ({ params, body }) => {
    const { propertyId } = params!;
    return {
      url: createAndUpdatePath(propertyId),
      method: 'POST',
      body,
    };
  },
  updateOne: ({ params, body }) => {
    const { id, propertyId } = params!;
    return {
      url: `${createAndUpdatePath(propertyId)}/${id}`,
      method: 'PATCH',
      body,
    };
  },
  deleteOne: ({ params, body }) => {
    const { propertyId, id } = params!;
    return {
      url: `${createAndUpdatePath(propertyId)}/${id}`,
      method: 'DELETE',
      body,
    };
  },
  checkAvailabilityOverbooking: ({ params, query }) => {
    const { propertyId, unitId } = params!;
    const { fromDate, toDate, slotId } = query! as {
      fromDate?: string;
      toDate?: string;
      slotId?: string;
    };

    const queryParams = new URLSearchParams();
    if (fromDate) queryParams.append('from', fromDate);
    if (toDate) queryParams.append('to', toDate);
    if (slotId) queryParams.append('slotId', slotId);

    return {
      url: `${maintenanceAvailabilityPath(propertyId)}/${unitId}?${queryParams.toString()}`,
    };
  },
};

export default roomRackApi;
