import React, { createContext, useContext } from 'react';
import useSearchParams from '@utils/hooks/useSearchParams';

export enum Integration {
  APALEO = 'apaleo',
  SHINE = 'shine',
  NONE = 'none',
}

export enum SearchParams {
  INTEGRATION = 'integration',
  SUBJECT_ID = 'subjectId',
  CONTENT_ONLY = 'contentOnly',
}

function isValidIntegration(value: string | null): value is Integration {
  return value !== null && Object.values(Integration).includes(value as Integration);
}

interface AppContext {
  isApaleoTab: boolean;
  isContentOnly: boolean;
  integration: Integration;
  isFullScreen: boolean;
}

export const AppContext = createContext({} as AppContext);

interface Props {
  children: React.ReactNode;
}
export function AppProvider(props: Props) {
  const searchParams = useSearchParams();

  const isApaleoTab = searchParams.has(SearchParams.SUBJECT_ID);
  const isContentOnly = searchParams.get(SearchParams.CONTENT_ONLY) === 'true' ? true : false;
  const integrationParam = searchParams.get(SearchParams.INTEGRATION);

  const integration = isValidIntegration(integrationParam) ? integrationParam : Integration.NONE;
  const isFullScreen = integration === Integration.APALEO && isContentOnly;

  const state: AppContext = {
    isApaleoTab,
    isContentOnly,
    integration,
    isFullScreen,
  };
  return <AppContext.Provider value={state} {...props} />;
}

export default function useAppContext() {
  return useContext(AppContext);
}
