import { useTranslation } from 'react-i18next';
import { PlusCircleIcon } from '@heroicons/react/solid';

interface AddSlotProps {
  handleNewSlot: () => void;
}

export const AddSlotButton = ({ handleNewSlot }: AddSlotProps) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={handleNewSlot}
      className="border bg-th-secondary rounded-md py-[6px] px-4 text-white font-normal text-sm leading-[18px] flex justify-center items-center gap-x-2"
    >
      <PlusCircleIcon width={18} height={18} />
      <span>{t('newSlot')}</span>
    </button>
  );
};
