import {
  DamageReportActionRequired,
  DamageReportArea,
  StandardizedDamageReport,
  TaskPriority,
  Team,
} from '@typings/enums';
import { CleaningInterval } from '@typings/types';

const standardizedDamageReports = {
  [StandardizedDamageReport.BATHROOM_FAN_LOUD]: 'Głośny wentylator w łazience',
  [StandardizedDamageReport.LIGHT_NOT_WORKING]: 'Światło nie działa',
  [StandardizedDamageReport.SINK_BLOCKED]: 'Zlew jest zablokowany',
  [StandardizedDamageReport.SHOWERDRAIN_BLOCKED]: 'Odpływ prysznica jest zablokowany',
  [StandardizedDamageReport.BATHROOM_FILTER]: 'Filtr łazienkowy',
  [StandardizedDamageReport.SINK]: 'Zlew',
  [StandardizedDamageReport.TOILET]: 'Toaleta',
  [StandardizedDamageReport.SHOWER]: 'Prysznic',
  [StandardizedDamageReport.STOVE]: 'Kuchenka',
  [StandardizedDamageReport.FRIDGE_MINIBAR]: 'Lodówka/minibar',
  [StandardizedDamageReport.DOOR]: 'Drzwi',
  [StandardizedDamageReport.WINDOW]: 'Okno',
  [StandardizedDamageReport.AC]: 'Klimatyzacja',
  [StandardizedDamageReport.WIFI]: 'Wifi',
  [StandardizedDamageReport.LIGHTBULB]: 'Żarówka',
  [StandardizedDamageReport.CHAIRS]: 'Krzesła',
  [StandardizedDamageReport.HEATING]: 'Ogrzewanie',
  [StandardizedDamageReport.TV]: 'Telewizor',
  [StandardizedDamageReport.TABLES]: 'Tabele',
  [StandardizedDamageReport.CHROMECAST]: 'Chromecast',
};

const teamNames = {
  [Team.HOUSE_KEEPING]: 'Zespół HSK',
  [Team.MAINTENANCE]: 'Zespół serwisowy',
  [Team.OPERATIONS]: 'Zespół operacyjny',
  [Team.ICT]: 'ICT',
};

const damageReportAreas = {
  [DamageReportArea.KITCHEN]: 'Kuchnia',
  [DamageReportArea.BED]: 'Łóżko',
  [DamageReportArea.BATHROOM]: 'Łazienka',
  [DamageReportArea.SEATING_AREA]: 'Strefa siedząca',
  [DamageReportArea.WALLS_CEILING_FLOOR]: 'Ściany/sufit/podłoga',
  [DamageReportArea.ICT]: 'ICT',
};

const damageReportActionsRequired = {
  [DamageReportActionRequired.CLEAN]: 'Posprzątać',
  [DamageReportActionRequired.REPLACE]: 'Zamienić',
  [DamageReportActionRequired.REPAIR]: 'Naprawić',
  [DamageReportActionRequired.CHECK]: 'Sprawdzić',
  [DamageReportActionRequired.OTHER]: 'Inny',
};

const taskPriorities = {
  [TaskPriority.LOW]: 'Niski',
  [TaskPriority.HIGH]: 'Wysoki',
};

const popupNotifications = {
  taskDeletionNotification: 'Zadanie usunięte',
  lostAndFoundDeletionNotification: 'Zgubiony i znaleziony przedmiot usunięty',
  damageReportDeletionNotification: 'Raport o uszkodzeniach usunięty',
  taskCreationNotification: 'Zadanie utworzone',
  lostAndFoundCreationNotification: 'Zgubiony i znaleziony przedmiot utworzony',
  damageReportCreationNotification: 'Raport o uszkodzeniach utworzony',
  taskCompletionNotification: 'Zadanie zakończone',
  lostAndFoundCompletionNotification: 'Zgubiony i znaleziony przedmiot zakończony',
  damageReportCompletionNotification: 'Raport o uszkodzeniach zakończony',
  taskUpdateNotification: 'Zadanie zaktualizowane',
  lostAndFoundUpdateNotification: 'Zgubiony i znaleziony przedmiot zaktualizowany',
  damageReportUpdateNotification: 'Raport o uszkodzeniach zaktualizowany',
  pleaseSelectRoomsNotification: 'Wybierz pokoje',
  noShowReportNotification: 'No-show zgłoszony dla pokoju {{number}}',
  checkInReportNotification: 'Zameldowanie zgłoszone dla pokoju {{number}}',
  damageReportWorkingTimeScheduledNotification: 'Raport o uszkodzeniach zaplanowany na {{date}}',
  hskDelayNotification: 'HSK opóźnienie dla pokoju {{number}}. Czas zameldowania ustawiony na {{time}}',
};

const cleaningInterval = {
  next: 'Next cleaning',
  interval: 'Cleaning interval',
  timeRange: 'Time range',
  lastCleaning: 'Last cleaning',
  stayoverCleaning: 'Stayover cleaning',
  [CleaningInterval.WEEKLY_OR_END]: 'Weekly or end of stay',
  [CleaningInterval.BIWEEKLY]: 'Co dwa tygodnie',
  [CleaningInterval.DAILY]: 'Codziennie',
  [CleaningInterval.NONE]: 'Nic',
  [CleaningInterval.EVERY_4_DAYS]: 'Every 4 days',
};

export default {
  ...teamNames,
  standardizedDamageReports,
  damageReportAreas,
  damageReportActionsRequired,
  taskPriorities,
  popupNotifications,
  cleaningInterval,
  moreFilters: 'Więcej filtrów',
  resetAllFilters: 'Zresetuj wszystkie filtry',
  reset: 'Zresetuj',
  cleaned: 'Posprzątany',
  allRoomsCleaned: `Wszystkie pokoje czyste`,
  dirty: 'Brudny',
  readyToClean: 'Gotowy do sprzątania',
  stayoverReadyToClean: 'Gotowy do sprzątania podczas pobytu',
  occupiedCleaningToday: 'Zajęty - sprzątanie dzisiaj',
  readyToInspect: 'Gotowy do sprawdzenia',
  stayoverInspection: 'Kontrola podczas pobytu',
  vacant: 'Pusty',
  occupied: 'Zajęty',
  clean: 'Czysty',
  daily: 'Codziennie',
  weekly: 'Co tydzień',
  biweekly: 'Co dwa tygodnie',
  monthly: 'Co miesiąc',
  none: 'Nic',
  tasks: 'Zadania',
  notifications: 'Powiadomienia',
  more: 'Więcej',
  select: 'Wybierz',
  property: 'Nieruchomość',
  result: 'Wynik',
  results: 'Wyniki',
  properties: 'Nieruchomości',
  rooms: 'Pokoje',
  team: 'Zespół',
  dueDate: 'Termin ostateczny',
  date: 'Data',
  close: 'Zamknij',
  search: 'Szukaj',
  login: 'Zaloguj się',
  loading: 'Ładowanie...',
  anErrorHasOccurred: 'Wystąpił błąd',
  chooseDate: 'Wybierz datę',
  selectDate: 'Wybierz datę',
  selectADate: 'Wybierz datę',
  markAsClean: 'Zaznacz jako czysty',
  markAsReadyToInspect: 'Zaznacz jako gotowy do sprawdzenia',
  viewTasks: 'Wyświetl zadania',
  eci: 'ECI - wczesne zameldowanie',
  lco: 'LCO - późne wymeldowanie',
  snacks: 'SNACKS',
  roomLog: `Log pokoju`,
  taskLog: `Log zadania`,
  seeDetails: 'Patrz szczegóły',
  assignedTasks: 'Przydzielone zadania',
  delete: 'Usuń',
  deleteTask: 'Usuń zadanie',
  deleteTaskConfirmationMessage: 'Czy na pewno chcesz usunąć to zadanie?',
  deleteTaskRepeatsConfirmationMessage:
    'To jest powtarzające się zadanie. Możesz również usunąć tylko to lub powtarzające się przyszłe zadania',
  deleteOnlyThisTask: 'Usuń tylko to zadanie',
  deleteRepeatingTask: 'Usuń powtarzające się zadanie',
  yes: 'Tak',
  no: 'Nie',
  pleaseWaitAFewSeconds: 'Proszę poczekaj kilka sekund',
  deleting: 'Usuwanie',
  lostAndFound: 'Zgubiony i znaleziony',
  current: 'Aktualny',
  archive: 'Archiwizuj',
  edit: 'Edytuj',
  markAsResolved: 'Oznacz jako rozwiązane',
  markAsUnresolved: 'Oznacz jako nierozwiązany',
  add: 'Dodaj',
  area: 'Obszar',
  addTask: 'Dodaj zadanie',
  editTask: 'Edytuj zadanie',
  seeTaskDetails: 'Zobacz zadanie',
  savePicturesAndStartCleaning: 'Zapisz i rozpocznij sprzątanie',
  savePictures: 'Zapisz zdjęcia',
  takePhoto: 'Zrób zdjęcie',
  addArea: 'Dodaj obszar',
  addAreaDescription: 'Dodaj obszar hotelu do tego zadania',
  selectProperty: 'Wybierz nieruchomość',
  selectRooms: 'Wybierz pokoje',
  selectTeam: 'Wybierz zespół',
  selectDueDate: 'Wybierz datę',
  hskTeam: 'Zespół HSK',
  save: 'Zapisz',
  actionIsRequired: `Akcja jest wymagana. Usuń tytuł i wybierz akcję z listy.`,
  noActionSelected: `Brak wybranej akcji`,
  changeSearchAction: `Spróbuj zmienić tekst wyszukiwania`,
  noActionFound: `Brak znalezionych akcji`,
  selectActionHelp: `Nie możesz znaleźć akcji? Skontaktuj się z zespołem Ops, aby ją dodać.`,
  writeToSelectAction: 'Pisz, aby wybrać akcję',
  writeTask: 'Napisz zadanie',
  repetition: 'Powtórzenie',
  noRepetition: 'Brak powtórzenia',
  repetitionTime: 'Czas powtórzeń',
  doesNotRepeat: 'Nie powtarzaj',
  day: 'Dzień',
  week: 'Tydzień',
  month: 'Miesiąc',
  repeatOn: 'Powtórz w',
  repeatsEvery: 'Powtórz co',
  description: 'Opis',
  title: 'Tytuł',
  addTitle: 'Dodaj tytuł',
  titleIsRequired: 'Tytuł jest wymagany',
  titleMaxLength: 'Tytuł powinien mieć mniej niż {{maxLength}} znaków.',
  teamIsRequired: 'Zespół jest wymagany',
  openTasks: 'Otwarte zadania',
  arrival: 'Przyjazd',
  departure: 'Wyjazd',
  checkIn: 'Zameldować się',
  times: 'Czasy',
  guests: 'Goście',
  name: 'Nazwisko',
  additionalInfo: 'Dodatkowe informacje',
  addLostAndFound: 'Dodaj zagubiony i znaleziony',
  addDamageReport: 'Dodaj raport o uszkodzeniach',
  unitIsRequired: 'Jednostka jest wymagana',
  addItem: 'Dodaj przedmiot',
  editItem: 'Edytuj przedmiot',
  startCleaning: 'Zacznij sprzątanie',
  endCleaning: 'Zakończ sprzątanie',
  endCleaningMessage: 'Jest 1 otwarte zadanie. Czy na pewno chcesz zakończyć sprzątanie?',
  endCleaningMessage_plural: 'Istnieją {{count}} otwarte zadania. Czy na pewno chcesz zakończyć sprzątanie?',
  cancelAndViewTasks: 'Anuluj i przeglądaj zadania',
  ignoreAndMarkAsReadyToInspect: 'Ignoruj ​​i zaznacz jako gotowe do sprawdzenia',
  ignoreAndMarkAsCleaned: 'Ignoruj ​​i zaznacz jako posprzątane',
  damageReports: 'Raporty o uszkodzeniach',
  confirm: 'Potwierdź',
  selectRoomOrArea: 'Wybierz pokój/obszar lub',
  createNewOne: 'Utwórz nowy',
  today: 'Dzisiaj',
  tomorrow: 'Jutro',
  noTasksMessage: 'Brak zadań',
  cleaningStatus: 'Sprzątanie',
  logOut: 'Wyloguj',
  language: 'Język',
  selectLanguage: 'Wybierz język',
  isStandardized: 'Jest zdefiniowany?',
  selectStandardizedTask: 'Wybierz zdefiniowane zadanie',
  overdue: 'Zaległy',
  arrivals: 'Przyjazdy',
  memberArrivals: 'Member arrivals',
  departures: 'Wyjazdy',
  stayovers: 'Stayovers',
  occupancy: 'Obłożenie OTB',
  midstayCleaning: 'Sprzątanie podczas pobytu',
  roomsToSell: 'Pokoje na sprzedaż',
  editImages: 'Edytuj obrazy',
  toClean: 'Do posprzątania',
  toInspect: 'Do sprawdzenia',
  noRoomsForCleaningToday: 'Brak pokoi do posprzątania dzisiaj',
  noShow: 'Brak zameldowania',
  noShowReportConfirmationMessage: 'Potwierdź, że numer pokoju {{number}} nie jest show.',
  noShowReportQuestion: 'Czy gość zameldował się wczoraj?',
  checkInReportConfirmationMessage: 'Potwierdź, że gość zameldował się w pokoju {{number}}.',
  noResultsSearchMessage: 'Brak wyników dla kryteriów wyszukiwania',
  cancel: 'Anuluj',
  selectCleaner: 'Wybierz sprzątacza',
  selectAll: 'Zaznacz wszystko',
  floor: 'Podłoga',
  notAssigned: 'Nie przypisano',
  assignXRooms: 'Przypisz pokoje {{total}}',
  assignRooms: 'Przypisz pokoje',
  approvedLCOs: 'Zatwierdzone późne wymeldowanie',
  cleaner: 'Sprzątacz',
  roomsCleanForToday: 'Wszystkie pokoje są na dziś czyste! 🎉🎉',
  viewDamageReports: 'Zobacz raporty o szkodach',
  noTasks: 'Brak zadań',
  noDamageReports: 'Brak raportów o szkodach',
  dailyCleaningReport: 'Raport codziennego czyszczenia',
  stayover: 'Kontynuacja pobytu',
  roomMoveFrom: 'Przeniesienie z pokoju {{value}}',
  roomMoveTo: 'Przeniesienie do pokoju {{value}}',
  unassignAll: 'Usuń {{total}} przypisania',
  unassignRoomsConfirmationMessage: 'Czy na pewno usunąć przypisanie z {{total}} pokoi?',
  unassignRoomsTitle: 'Usuń przypisanie pokoje',
  noRoomAssigned: 'Brak przypisanego pokoju',
  action: 'Akcja',
  hideActions: 'Ukryj akcje',
  showActions: 'Pokaż akcje',
  actionsSelected: '{{total}} wybrane(a) akcje(a)',
  disableActionTitleModal: 'Zresetuj filtr {{name}}',
  disableActionDescriptionModal: 'Aby użyć filtra {{currentFilter}}, zresetuj filtr {{resetFilter}}.',
  actionRequired: 'Wymagane działanie',
  priority: 'Priorytet',
  lcoUntil: 'Późne wymeldowanie do',
  created: 'Utworzony',
  startNow: 'Zacznij teraz',
  scheduleTime: 'Zaplanuj czas',
  viewInfo: 'Zobacz informacje',
  reminder: 'Przypomnienie',
  reminderTaskNotificationText: 'Twoje zaplanowane zadanie kończy się dzisiaj',
  inProgress: 'W trakcie',
  scheduled: 'Zaplanowany',
  moveMidstayCleaning: 'Czy przesunąć sprzątanie podczas pobytu?',
  moveMidstayTitle: 'Przesuń sprzątanie podczas pobytu',
  moveMidstayDescription: 'Czy na pewno chcesz przesunąć sprzątanie podczas pobytu na {{date}}?',
  moveMidstayNotPossible: 'Przepraszamy! Sprzątanie podczas pobytu nie można przenieść na kolejny dzień!',
  moveMidstayNotification: 'Sprzątanie podczas pobytu przesunięto na {{date}}.',
  pleaseContactOps: 'Skontaktuj się z zespołem operacyjnym',
  midCleanMovedTo: 'Sprzątanie podczas pobytu przesunięto na {{value}}.',
  optional: 'opcjonalny',
  hskDelay: 'Opóźnienie HSK',
  hskDelays: 'Opóźnienia HSK',
  hskDelayMessage: 'Aktualizacja czasu zameldowania dla pokoju {{number}}',
  notStarted: 'Nie rozpoczęty',
  resolved: 'Rozwiązany',
  noDamageReportsToday: 'Brak raportów dotyczących szkód na dziś',
  yourTask: 'Twoje zadanie',
  yourTaskGX: 'Twoje zadanie od GX',
  damageReportedByGX: 'Uszkodzenie zgłoszone przez GX',
  stay: 'Daty pobytu',
  checkOut: 'Godzina wymeldowania',
  noPermission: 'Nie masz uprawnień, proszę skontaktować się z pomocą techniczną.',
  approvedCheckoutTime: 'Zatwierdzony czas wymeldowania',
  requestedCheckoutTime: 'Żądany czas wymeldowania',
  checkinTime: 'Czas check-inu',
  commentForHousekeeping: 'Komentarz dla personelu sprzątającego',
  numberOfApprovedLCOs: 'Liczba zatwierdzonych LCO',
  notSet: 'Nie ustawiono',
  vip: 'VIP',
  extraService: 'Extra service',
  reservationMissing: 'Rezerwacja najprawdopodobniej została anulowana, przejdź zamiast tego do zakładki Ogólne.',
  confirmFor: 'Potwierdź na {due date}',
  confirmAnotherDay: 'Potwierdź inny dzień',
  taskConfirmed: 'Potwierdziłeś zadanie na {due date}.',
  confirmYourTask: 'Potwierdź swoje zadanie',
  confirmYourTaskGX: 'Potwierdź swoje zadanie z GX.',
  confirmHighPriorityTaskMessage:
    'Czy naprawdę chcesz ustawić dzisiejszy termin wykonania? Prawdopodobnie to zadanie nie zostanie dzisiaj potwierdzone i ukończone.',
  setTomorrow: 'Ustaw jutro',
  keepToday: 'Zachowaj dzisiaj',
  createTask: 'Utwórz zadanie',
  confirmationNeeded: 'Potrzebne potwierdzenie',
  pictureTooBlurry: `Zdjęcie jest zbyt rozmyte. Proszę zrób wyraźne zdjęcie i spróbuj ponownie.`,
  tryAgain: `Spróbuj ponownie`,
  addDescription: `Dodaj opis`,
  togglePushNotificationsLabel: `Powiadomienia push dla iOS`,
  roomRack: `Pokoje`,
  newSlot: `Nowy slot`,
  searchByRoomNumber: `Numer pokoju`,
  reservation: `Rezerwacja`,
  outOfOrder: `Out of Order`,
  outOfService: `Out of Service`,
  outOfInventory: `Out of Inventory`,
  slotType: `Typ slotu`,
  selectType: `Wybierz typ`,
  reason: `Powód`,
  selectReason: `Wybierz powód`,
  selectDateAndTime: `Data i godzina`,
  startDate: `Data rozpoczęcia`,
  startTime: `Godzina rozpoczęcia`,
  endDate: `Data zakończenia`,
  endTime: `Czas zakończenia`,
  room: `Pokój`,
  selectRoom: `Wybierz pokój`,
  cleanAfterBlock: `Posprzątaj po slocie`,
  addSlot: `Dodaj slot`,
  updateSlot: `Zmień slot`,
  errorStartDatePastSlotForm: `Data rozpoczęcia nie może być w przeszłości`,
  errorEndDatePastSlotForm: `Data końcowa nie może być wcześniej niż data początkowa`,
  errorEndTimePastSlotForm: `Godzina końcowa nie może być wcześniej niż godzina rozpoczęcia`,
  errorMinDurationSlotForm: `Minimalny czas slotu to 30 minut`,
  errorOverbookedSlotForm: `Utworzenie slotu może prowadzić do nadmiernej liczby rezerwacji w wybranych dniach. Utwórz go tylko wtedy, gdy jest to naprawdę konieczne, w przeciwnym razie wybierz inne dni.`,
  outOfOrderInfo: `OOO - Out of Order. Powinno być używane, gdy pokój jest niefunkcjonalny i nie może być używany przez gościa.`,
  outOfServiceInfo: `OOS - Out of Service. Pozwala na sprzedaż pokoju. Pokój oznaczony jako Out of Service jest w pełni funkcjonalny i ma jedynie drobne wady kosmetyczne.`,
  outOfInventoryInfo: `OOI - Out of Inventory. Używane przez zespół Launch do długoterminowych planów konserwacyjnych, takich jak renowacja całego piętra.`,
  maintenanceReason: `Konserwacja`,
  cleaningIssueReason: `Problem z czyszczeniem`,
  roomMoveBlockReason: `Zmiana pokoju`,
  bedBugsReason: `Pluskwy`,
  smokingReason: `Palenie`,
  vipGuestReason: `Gość VIP`,
  pmpReason: `PMP`,
  rampUpSruReason: `Ramp-up: strategiczny`,
  rampUpRnrReason: `Ramp-up: pokój niegotowy`,
  hoReason: `Optymalizacja HSK`,
  othersReason: `Inny powód`,
  createdInApaleo: `Utworzony w Apaleo`,
  reasonNotApaleo: `\`Utworzony w Apaleo\` nie jest poprawnym powodem. Proszę wybrać inny.`,
  quarantineReason: `Kwarantanna`,
  slotLog: `Log`,
  deleteSlot: `Usuń slot`,
  deleteSlotLabel: `Proszę podać powód usunięcia`,
  errorDeleteSlot: `Nie możesz usunąć slotu.`,
  errorDeleteInfoSlot: `Trwający slot nie może zostać usunięty, zamiast tego można go skrócić.`,
  fieldRequired: `{{field}}: pole jest wymagane`,
  from: `od `,
  until: `do `,
  issueResolveDeleteReason: `Problem został rozwiązany / slot nie jest potrzebny`,
  createdByMistakeDeleteReason: `Slot utworzony przez pomyłkę`,
  guestRejectedMoveDeleteReason: `Gość odrzucił zmianę pokoju`,
  deleteReasonIsRequired: `Powód usunięcia jest wymagany`,
  editSlot: `Edytuj slot`,
  earlyCheckinByHour: `ECI - sprzątanie do {{hour}}:00`,
};
