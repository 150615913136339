import { StarIcon } from '@heroicons/react/solid';
import { PropertyType, UnitInfoType } from '@typings/types';
import { useTranslation } from 'react-i18next';
import { EyeOffIcon } from '@heroicons/react/outline';
import icons from '@constants/icons';
import { UnitStatus } from '@typings/enums';
import { formatDate, TIME_FORMAT } from '@utils/dateUtils';
import cn from 'classnames';
import { OPEN_TASKS_SECTION_KEY } from '@utils/unitUtils';
import { isMemberBenefitsActivated, MemberBenefitsType } from '@utils/propertyUtils';
import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';

interface Props {
  data: UnitInfoType;
  property: PropertyType;
  dailyViewSectionKey?: string;
  onClick: () => void;
}

const underMaintenanceUnitBackground = {
  backgroundColor: '#ffffff',
  backgroundImage:
    "url(\"data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ff4641' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E\")",
};

const noShowUnitBackground = {
  backgroundColor: '#ffffff',
  backgroundImage:
    "url(\"data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2361948f' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E\")",
};

const unitStatusesToShowMemberIcon = [UnitStatus.READY_TO_CLEAN, UnitStatus.VACANT, UnitStatus.READY_TO_INSPECT];
const unitStatusesToShowSnacksIcon = [UnitStatus.READY_TO_CLEAN, UnitStatus.READY_TO_INSPECT, UnitStatus.VACANT];

const getUnitStyle = (isMaintenance?: boolean, isNoShow?: boolean) => {
  if (isMaintenance) {
    return underMaintenanceUnitBackground;
  }
  if (isNoShow) {
    return noShowUnitBackground;
  }
  return {};
};

export default function UnitInfo({ data: unitData, property, dailyViewSectionKey, onClick }: Props) {
  const {
    arrival,
    departure,
    number,
    status,
    tasksCount,
    damageReportsCount,
    lateCheckOut,
    earlyCheckIn,
    isVip,
    currentCleaningId,
    isMaintenance,
    isNoShow,
    noShowReported,
    hskDelayUntil,
    roomMoveFrom,
    roomMoveTo,
    isMember,
  } = unitData;
  const { t } = useTranslation();
  const { isOperationsRole } = useRoleBasedUI();

  const longNumber = number.length > 8;
  const { id: propertyId, memberBenefits: propertyMB, enablePaidEci } = property;
  const isOperations = isOperationsRole(propertyId);

  const memberBenefitsActivated = isMemberBenefitsActivated(propertyMB);
  const isMemberReservation = memberBenefitsActivated && isMember;
  const showMemberIcon = isMemberReservation && (unitStatusesToShowMemberIcon.includes(status) || lateCheckOut);
  const validForSnacksMemberBenefit = isMemberReservation && propertyMB.includes(MemberBenefitsType.FREE_SNACKS);
  const showSnacksIcon =
    unitStatusesToShowSnacksIcon.includes(status) || (status === UnitStatus.OCCUPIED_CLEANING_TODAY && isOperations);

  return (
    <div
      className={`rounded-lg ring-2 px-[10px] py-2 relative flex flex-col justify-between mr-3 my-2 cursor-pointer min-h-12 w-[80px] ${
        currentCleaningId ? 'ring-th-dark-blue' : 'ring-gray-100'
      }`}
      style={getUnitStyle(isMaintenance, isNoShow)}
      onClick={() => onClick()}
    >
      {/*requires overflowWrap to fix break-all issue https://github.com/tailwindlabs/tailwindcss/discussions/10545*/}
      <div
        className={cn(
          'flex justify-center font-serif font-bold text-th-dark-green text-center break-all',
          longNumber ? 'text-md' : 'text-lg',
        )}
        style={{ overflowWrap: 'anywhere' }}
      >
        {number}
      </div>
      <div className={'flex flex-row items-center space-x-1 justify-center'}>
        {!(enablePaidEci && earlyCheckIn) && (
          <>
            {arrival && <div className={'text-xs text-gray-400 leading-3'}>{formatDate(arrival, TIME_FORMAT)}</div>}
            {departure && !arrival && (
              <div className={'text-xs text-gray-400 leading-3'}>{formatDate(departure, TIME_FORMAT)}</div>
            )}
          </>
        )}
        {earlyCheckIn && !enablePaidEci && (
          <div
            className={
              'bg-th-dark-green rounded-sm text-white text-tiny font-bold flex justify-center items-center w-[20px] h-3 flex-shrink-0'
            }
          >
            {t('eci')}
          </div>
        )}
        {lateCheckOut && !earlyCheckIn && (
          <div
            className={
              'bg-th-dark-green rounded-sm text-white text-tiny font-bold flex justify-center items-center w-[20px] h-3 flex-shrink-0'
            }
          >
            {t('lco')}
          </div>
        )}
        {showMemberIcon && (
          <icons.member className={'flex justify-center items-center w-3 h-3 flex-shrink-0'}></icons.member>
        )}
        {validForSnacksMemberBenefit &&
          ((showSnacksIcon && (
            <div className={'flex justify-center items-center w-3 h-3 flex-shrink-0'}>
              <icons.snacks />
            </div>
          )) ||
            (dailyViewSectionKey === OPEN_TASKS_SECTION_KEY && (
              <div className={'flex flex-row items-center space-x-3 justify-center'}>
                <div
                  className={
                    'rounded-sm text-gray-400 text-tiny font-bold flex justify-center items-center w-[20px] h-3 flex-shrink-0'
                  }
                >
                  {t('snacks')}
                </div>
                <icons.snacks className={'flex justify-center items-center w-3 h-3 flex-shrink-0'} />
              </div>
            )))}
        {(roomMoveFrom || roomMoveTo) && (
          <icons.roomMove className={'w-4 h-4 text-th-secondary fill-current inline flex-shrink-0'} />
        )}
        {status === UnitStatus.READY_TO_CLEAN && hskDelayUntil && (
          <icons.hskDelay className={'w-4 h-4 text-th-secondary fill-current inline flex-shrink-0'} />
        )}
      </div>
      <div className={'flex flex-row absolute -top-2 -right-2 justify-end'}>
        {damageReportsCount ? (
          <span
            className={
              'ml-0.5 bg-[#309bf0] text-white inline-block w-4 h-4 flex justify-center items-center text-tiny rounded-full ring-2 ring-white font-bold'
            }
          >
            {damageReportsCount}
          </span>
        ) : null}
        {tasksCount ? (
          <span
            className={
              'ml-0.5 bg-th-accent text-white inline-block w-4 h-4 flex justify-center items-center text-tiny rounded-full ring-2 ring-white font-bold'
            }
          >
            {tasksCount}
          </span>
        ) : null}
      </div>
      {isVip ? (
        <div
          className={
            'w-4 text-center ml-1 bg-yellow-300 text-white inline-block py-0.5 px-0.5 text-tiny rounded-full absolute -bottom-2 -left-3 ring-2 ring-white font-bold'
          }
        >
          <StarIcon className={'h-3 w-3'} />
        </div>
      ) : null}
      {noShowReported ? (
        <div
          className={
            'w-4 text-center ml-1 bg-[#61948f] text-white inline-block py-0.5 px-0.5 text-tiny rounded-full absolute -bottom-1.5 -right-2 ring-2 ring-white font-bold'
          }
        >
          <EyeOffIcon className={'h-3 w-3'} />
        </div>
      ) : null}
    </div>
  );
}
