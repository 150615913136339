import { useCheckAvailabilityOverbooking } from '@api/api';

interface Props {
  propertyId: string;
  unitId: string;
  fromDate: string;
  toDate: string;
  slotId?: string;
}

function useCheckAvailabilityOverbookingSlot(props: Props) {
  const { data, ...queryResult } = useCheckAvailabilityOverbooking(props);

  return {
    ...queryResult,
    isSlotAvailable: data?.available,
  };
}

export default useCheckAvailabilityOverbookingSlot;
