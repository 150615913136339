import { enUS, de, vi, cs, it, srLatn, hr, uk, ru, es, ro, fr, nb, pl, tr, bg, nl } from 'date-fns/locale';
import { useLanguage } from './hooks/useLanguage';

const locales = {
  en: enUS, // English
  de: de, // German
  vi: vi, // Vietnamese
  cs: cs, // Czech
  it: it, // Italian
  sr: srLatn, // Serbian (Latin script)
  hr: hr, // Croatian
  uk: uk, // Ukrainian
  ru: ru, // Russian
  es: es, // Spanish
  ro: ro, // Romanian
  fr: fr, // French
  no: nb, // Norwegian
  pl: pl, // Polish
  tr: tr, // Turkish
  bg: bg, // Bulgarian
  nl: nl, // Dutch
};

export const getDateFnsCurrentLocale = () => {
  const { language } = useLanguage();

  return locales[language] || enUS;
};
