import Button from '@atoms/Button';
import LoadingMessage from '@atoms/LoadingMessage';
import { useAuth0 } from '@auth0/auth0-react';
import useAppContext, { Integration } from '@context/appContext';
import { useTranslation } from 'react-i18next';

interface Props {
  children: React.ReactNode;
}
function ProtectedRoutes({ children }: Props): JSX.Element {
  const { isAuthenticated, isLoading, loginWithRedirect, loginWithPopup, user } = useAuth0();

  const { isApaleoTab, integration } = useAppContext();
  const { t } = useTranslation();

  const onClickLogin = () => {
    if (isApaleoTab || integration === Integration.APALEO) {
      return loginWithPopup();
    }
    return loginWithRedirect({
      appState: {
        returnTo: window.location.href,
      },
    });
  };

  if (isLoading) {
    return (
      <div className="flex flex-1 justify-center">
        <LoadingMessage />
      </div>
    );
  }

  if (isAuthenticated && user) {
    return <>{children}</>;
  }

  return (
    <div className={'p-4 flex flex-1 flex-col justify-center items-center'}>
      <Button onClick={onClickLogin}>{t('login')}</Button>
    </div>
  );
}

export default ProtectedRoutes;
