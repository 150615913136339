import { useTranslation } from 'react-i18next';
import { SearchIcon } from '@heroicons/react/solid';
import { AutocompleteSimpleInput } from '@molecules/AutocompleteSimpleInput';

type Option = {
  id: string;
  title: string;
};

interface SearchByRoomProps {
  roomsData: Option[];
  filteredByRoomId: string | undefined;
  handleRoomSearch: (value: string) => void;
}

export const SearchByRoom = ({ roomsData, filteredByRoomId, handleRoomSearch }: SearchByRoomProps) => {
  const { t } = useTranslation();

  return (
    <div className="relative">
      <AutocompleteSimpleInput
        placeholder={t('searchByRoomNumber')}
        options={roomsData.map((room) => ({ label: room.title, value: room.id }))}
        value={filteredByRoomId || ''}
        onChange={handleRoomSearch}
        InputIcon={() => <SearchIcon width={14} height={14} className="flex-shrink-0" />}
        buttonClassName={`flex justify-between items-center w-full text-sm p-0 border 
          border-th-brown-100 rounded py-[6px] px-2 bg-transparent hover:bg-[#EDF0F3]`}
        inputClassName={'max-w-[148px] text-sm leading-[18px] pl-2'}
        chevronClassName={'h-4 w-4'}
        optionsClassName={'absolute'}
      />
    </div>
  );
};
