import Transition from '@atoms/Transition';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import UnitInfo from '@molecules/UnitInfo';
import { PropertyType, SVGIconType, UnitInfoType } from '@typings/types';
import { groupingAndSortingUnitsByCheckInTime } from '@utils/unitUtils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  sectionKey: string;
  Icon: SVGIconType;
  name: string;
  units: UnitInfoType[];
  onUnitClick: (unit: UnitInfoType) => void;
  defaultOpen?: boolean;
  property: PropertyType;
}

export default function DailyOverviewSection({
  sectionKey,
  name,
  Icon,
  units,
  onUnitClick,
  defaultOpen,
  property,
}: Props) {
  const { t } = useTranslation();

  const { earlyCheckInUnits, standardUnits, sortedEarlyCheckInUnitsKeys } = useMemo(() => {
    if (!property?.enablePaidEci)
      return { earlyCheckInUnits: {}, standardUnits: units, sortedEarlyCheckInUnitsKeys: [] };

    return groupingAndSortingUnitsByCheckInTime(units);
  }, [units]);

  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <div className={'flex flex-col bg-white rounded-lg p-4 shadow-lg flex-shrink-0'}>
          <Disclosure.Button className={'flex flex-row flex-1 items-center justify-between pb-3'}>
            <div className={'flex flex-row items-center space-x-3'}>
              <Icon className={'h-5 w-5 stroke-current text-th-brown'} aria-hidden="true" />
              <span className={'text-th-brown'}>{name}</span>
            </div>
            <div className={'flex flex-row items-center space-x-3'}>
              <span>{units.length}</span>
              <ChevronDownIcon className={`${open ? 'transform rotate-180' : ''} h-6 w-6`} />
            </div>
          </Disclosure.Button>
          <Transition>
            <Disclosure.Panel>
              {sortedEarlyCheckInUnitsKeys.length > 0 &&
                sortedEarlyCheckInUnitsKeys.map((key) => {
                  return (
                    <div key={key} className="mt-4 border border-th-red-200 rounded-lg">
                      <div className="text-th-gray-50 rounded-t-lg bg-th-accent text-base font-semibold font-sans px-3 py-1">
                        <span>{t('earlyCheckinByHour', { hour: key })}</span>
                      </div>
                      <div className={'flex flex-row flex-wrap p-4 gap-x-2'}>
                        {earlyCheckInUnits[key].map((u) => (
                          <UnitInfo
                            key={u.id}
                            data={u}
                            property={property}
                            dailyViewSectionKey={sectionKey}
                            onClick={() => onUnitClick(u)}
                          />
                        ))}
                      </div>
                    </div>
                  );
                })}
              {standardUnits.length > 0 && (
                <div className={'flex flex-row flex-wrap p-4 gap-x-2'}>
                  {standardUnits.map((u) => (
                    <UnitInfo
                      key={u.id}
                      data={u}
                      property={property}
                      dailyViewSectionKey={sectionKey}
                      onClick={() => onUnitClick(u)}
                    />
                  ))}
                </div>
              )}
            </Disclosure.Panel>
          </Transition>
        </div>
      )}
    </Disclosure>
  );
}
