import icons from '@constants/icons';
import { maintenanceSlotOptions } from '../utils';
import { SVGIconType } from '@typings/types';
import { APALEO_MAINTENANCE_SLOT_REASON, MaintenanceSlotReason } from '../RoomRackTable/types';
import { ChevronDoubleRightIcon, ClockIcon, ExclamationIcon } from '@heroicons/react/outline';

const NaApaleoReason = {
  icon: ExclamationIcon,
  translationKey: 'createdInApaleo',
  value: APALEO_MAINTENANCE_SLOT_REASON,
};

export const reasonsSlot: {
  icon: SVGIconType;
  translationKey: string;
  value: MaintenanceSlotReason;
}[] = [
  { icon: icons.maintenance, translationKey: 'maintenanceReason', value: MaintenanceSlotReason.MNT },
  { icon: icons.cleaningIssue, translationKey: 'cleaningIssueReason', value: MaintenanceSlotReason.CL },
  { icon: icons.roomMoveBlock, translationKey: 'roomMoveBlockReason', value: MaintenanceSlotReason.RM },
  { icon: icons.bedBugs, translationKey: 'bedBugsReason', value: MaintenanceSlotReason.BB },
  { icon: icons.smoking, translationKey: 'smokingReason', value: MaintenanceSlotReason.SMK },
  { icon: icons.vipGuest, translationKey: 'vipGuestReason', value: MaintenanceSlotReason.VIP },
  { icon: icons.pmp, translationKey: 'pmpReason', value: MaintenanceSlotReason.PMP },
  { icon: ClockIcon, translationKey: 'rampUpSruReason', value: MaintenanceSlotReason.RURNR },
  { icon: icons.rampUp, translationKey: 'rampUpRnrReason', value: MaintenanceSlotReason.RUSRU },
  { icon: ChevronDoubleRightIcon, translationKey: 'hoReason', value: MaintenanceSlotReason.HO },
  { icon: icons.other, translationKey: 'othersReason', value: MaintenanceSlotReason.OTHER },
];

export const allReasonsSlotIncludingNotValid = [...reasonsSlot, NaApaleoReason];

export const defaultOptions = {
  slotType: maintenanceSlotOptions,
  reason: reasonsSlot,
};

export const defaultMinMaxDate = 365;
export const maxTimeCleaningAfter = 14;
